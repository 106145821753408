<template>
  <div class="add-base">
    <div class="header"></div>
    <div class="base-body">
      <a-form-model
        ref="ruleForm"
        :model="form"
        :rules="rules"
        :label-col="labelCol"
        :wrapper-col="wrapperCol"
      >
        <a-form-model-item v-if="!edit" label="企业名称">
          <a-input-search
            v-model="form.name"
            placeholder="请输入企业名称"
            enter-button
            @search="onSearch"
          />
        </a-form-model-item>
        <a-form-model-item label="企业名称" ref="EnterpriseBH" prop="EnterpriseBH">
          <a-input v-if="edit" disabled v-model="form.EnterpriseMC" />
          <a-select
            v-else
            :disabled="edit"
            v-model="form.EnterpriseBH"
            placeholder="请选择企业"
            @change="handleChange"
          >
            <a-select-option
              v-for="(item, index) in dataEnterprise"
              :key="index"
              :value="item.EnterpriseBH"
            >
              {{ item.EnterpriseMC }}
            </a-select-option>
          </a-select>
        </a-form-model-item>
        <a-form-model-item label="基站名称" ref="station_bh" prop="station_bh">
          <a-input v-if="edit" disabled v-model="form.station_name" />
          <a-select
            v-else
            v-model="form.station_bh"
            :disabled="edit"
            placeholder="请选择基站"
          >
            <a-select-option
              v-for="(item, index) in dataBase"
              :key="index"
              :value="item.station_bh"
            >
              {{ item.station_name }}
            </a-select-option>
          </a-select>
        </a-form-model-item>
        <a-form-model-item label="开始时间" ref="KSSJ" prop="KSSJ">
          <a-date-picker
            show-time
            v-model="form.KSSJ"
            :format="dateFormatList"
            :disabled="datedisabled"
            type="date"
            @change="onKSSJChange"
            style="width: 100%"
          />
        </a-form-model-item>
        <a-form-model-item label="结束时间" ref="JSSJ" prop="JSSJ">
          <a-date-picker
            show-time
            v-model="form.JSSJ"
            :format="dateFormatList"
            :disabled="datedisabledJS"
            type="date"
            @change="onJSSJChange"
            style="width: 100%"
          />
        </a-form-model-item>
        <a-form-model-item label="停运原因" ref="TYYY" prop="TYYY">
          <a-input
            v-model="form.TYYY"
            :disabled="status == 1"
            dest
            style="height: 80px"
            type="textarea"
            placeholder="请填写停运原因"
          />
        </a-form-model-item>
        <a-form-model-item label="附件">
          <a-upload
            name="file"
            :multiple="true"
            :before-upload="beforeUpload"
            :file-list="fileList"
            @change="handleFileChange"
          >
            <a-button v-if="fileList.length < 5 && status == 2">
              <a-icon type="upload" /> 上传文件
            </a-button>
          </a-upload>
          <!-- <input type="file" name="上传附件" id="file" /> -->
        </a-form-model-item>
        <a-form-model-item :wrapper-col="{ span: 14, offset: 4 }">
          <a-button type="primary" @click="onSubmit" v-if="status == 2"> 提交 </a-button>
          <a-button
            v-if="status == 1"
            style="margin-left: 10px"
            type="primary"
            @click="onModify"
          >
            修改
          </a-button>
          <a-button
            v-if="edit"
            style="margin-left: 10px"
            type="primary"
            @click="onDelete"
          >
            删除
          </a-button>
          <a-button style="margin-left: 10px" @click="onCancel"> 返回 </a-button>
        </a-form-model-item>
      </a-form-model>
    </div>
    <div class="footer"></div>
    <div class="popContainer" v-show="popContainer">
      <img :src="imgUrl" style="width: 150px; height: 150px" alt="" />
    </div>
  </div>
</template>
<script>
import { http } from "../../utils/Http.js";
const axios = require("axios");
import moment from "moment";

import dayjs from "dayjs";
var quarterOfYear = require("dayjs/plugin/quarterOfYear");
dayjs.extend(quarterOfYear);
export default {
  data() {
    return {
      moment,
      labelCol: { span: 4 },
      wrapperCol: { span: 18 },
      dataEnterprise: [],
      dataBase: [],
      dateFormatList: "YYYY-MM-DD HH",
      form: {
        name: "",
        EnterpriseBH: undefined,
        station_bh: undefined,
        KSSJ: dayjs().format("YYYY-MM-DD HH"),
        JSSJ: dayjs().add(1, "day").format("YYYY-MM-DD HH"),
        // KSSJ: "",
        // JSSJ: "",
        TYYY: "",
        EnterpriseMC: "",
        station_name: "",
      },
      rules: {
        EnterpriseBH: [{ required: true, message: "请选择企业", trigger: "change" }],
        station_bh: [{ required: true, message: "请选择基站", trigger: "change" }],
        KSSJ: [{ required: true, message: "请选择开始时间", trigger: "change" }],
        JSSJ: [{ required: true, message: "请选择结束时间", trigger: "change" }],
        TYYY: [{ required: true, message: "请输入停运原因", trigger: "blur" }],
      },

      //上传文件
      fileList: [
        // {
        //   uid: "1",
        //   name: "xxx.png",
        //   status: "done",
        //   url: "http://www.baidu.com/xxx.png",
        // },
        // {
        //   uid: "2",
        //   name: "yyy.png",
        //   status: "done",
        //   url: "http://www.baidu.com/yyy.png",
        // },
      ],

      //遮罩层
      popContainer: false,
      imgUrl: require("../../assets/img/pics/loading.gif"),

      //状态 1 编辑    2 添加
      status: null,

      //点击编辑按钮进入
      edit: false,

      //日期是否能够编辑
      datedisabled: false,
      datedisabledJS: false,
    };
  },

  created() {
    // this.form.KSSJ = dayjs().format("YYYY-MM-DD HH");
    // this.form.JSSJ = dayjs().add(1, "day").format("YYYY-MM-DD HH");

    let TYID = this.$route.params.TYID;
    //状态 1 编辑    2 添加
    if (TYID == 0) {
      this.status = 2;
    } else if (TYID) {
      this.edit = true;
      this.status = 1;
      this.datedisabled = true;
      this.datedisabledJS = true;
    }
  },

  mounted() {
    //获取所有企业
    if (this.edit) {
      this.HJJC_STATION_NOOUTAGE_LIST();
    }

    if (this.status == 1) {
      //获取停运基站详细
      this.HTTC_STATION_OUTAGE_GET();
    }

    // var file = document.getElementById("file");
    // file.onchange = function () {
    //   console.log(this);
    //   console.log(this.files[0]);
    //   let formData = new FormData();
    //   // 上传的文件
    //   formData.append("file", this.files[0]);
    // };
  },

  methods: {
    //查询
    onSearch(value) {
      //获取所有企业
      this.HJJC_STATION_NOOUTAGE_LIST(value, 1);
    },

    //获取所有企业接口
    HJJC_STATION_NOOUTAGE_LIST(QYMC, type) {
      let url = "";
      //搜索企业
      if (QYMC) {
        url = "/data/q30/HJJC_STATION_NOOUTAGE_LIST_V2.aspx";
      } else {
        //获取所有企业
        url = "/data/q30/HJJC_STATION_NOOUTAGE_LIST.aspx";
      }
      var me = this;
      http({
        url: url,
        data: {
          CKM: me.$store.state.loggedIn.CKM,
          TYPE: 1,
          QYMC: QYMC,
        },
        success(obj) {
          console.log(obj);
          me.dataEnterprise = obj.Data;

          if (obj.Data && obj.Data.length > 0) {
            //添加
            if (me.$route.params.TYID == 0) {
              me.form.EnterpriseBH = obj.Data[0].EnterpriseBH;
            }

            me.HJJC_STATION_NOOUTAGE_LIST_base(obj.Data[0].EnterpriseBH);
          } else {
            me.form.EnterpriseBH = "";
            me.form.station_bh = "";
          }
        },
        failure(obj) {},
        me,
      });
    },

    //选择企业后调用获取基站接口
    handleChange(value) {
      console.log(value);
      this.HJJC_STATION_NOOUTAGE_LIST_base(value);
    },

    //获取所有基站接口
    HJJC_STATION_NOOUTAGE_LIST_base(EnterpriseBH) {
      var me = this;
      if (EnterpriseBH) {
        http({
          url: "/data/q30/HJJC_STATION_NOOUTAGE_LIST.aspx",
          data: {
            CKM: me.$store.state.loggedIn.CKM,
            TYPE: 2,
            EnterpriseBH: EnterpriseBH,
          },
          success(obj) {
            console.log(obj);
            me.dataBase = obj.Data;
            if (obj.Data && obj.Data.length > 0) {
              //添加
              if (me.$route.params.TYID == 0) {
                me.form.station_bh = obj.Data[0].station_bh;
              }
            } else {
              me.form.station_bh = "";
            }
          },
          failure(obj) {},
          me,
        });
      }
    },

    //获取停运基站详细
    HTTC_STATION_OUTAGE_GET() {
      let me = this;
      let param = {
        CKM: me.$store.state.loggedIn.CKM,
        TYID: this.$route.params.TYID,
      };
      console.log(param);
      http({
        url: "/data/q30/HTTC_STATION_OUTAGE_GET.aspx",
        data: param,
        success(obj) {
          if (obj.Data && obj.Data.length > 0) {
            console.log(obj);

            me.form.TYYY = obj.Data[0].TYYY;
            me.form.JSSJ = dayjs(obj.Data[0].JSSJ).format("YYYY-MM-DD HH");
            me.form.KSSJ = dayjs(obj.Data[0].KSSJ).format("YYYY-MM-DD HH");
            me.form.EnterpriseMC = obj.Data[0].EnterpriseMC;
            me.form.station_name = obj.Data[0].station_name;

            //开始时间大于当前时间才能修改
            if (
              dayjs(me.form.KSSJ).format("YYYY-MM-DD") < dayjs().format("YYYY-MM-DD") ||
              me.edit == true
            ) {
              me.datedisabled = true;
            }

            //结束时间大于当前时间才能修改
            if (
              dayjs(me.form.JSSJ).format("YYYY-MM-DD") < dayjs().format("YYYY-MM-DD") ||
              me.edit == true
            ) {
              me.datedisabledJS = true;
            }

            // me.form.JSSJ = "2021-03-12 17";
            // me.form.KSSJ = "2021-03-15 17";
            me.form.EnterpriseBH = obj.Data[0].EnterpriseBH;
            me.form.station_bh = obj.Data[0].station_bh;
            console.log(me.form);

            let file_name = obj.Data[0].file_name;
            let file_adrs = obj.Data[0].file_adrs;
            if (file_adrs && file_adrs.indexOf("|||") != -1) {
              let file_names = file_name.split("|||");
              let file_adrss = file_adrs.split("|||");
              for (let i = 0; i < file_adrss.length; i++) {
                me.fileList.push({
                  uid: "uid" + i,
                  name: file_names[i],
                  status: "done",
                  url: file_adrss[i],
                });
              }
            } else {
              me.fileList.push({
                uid: "uid",
                name: file_name,
                status: "done",
                url: file_adrs,
              });
            }
          }
        },
        failure(obj) {},
        me,
      });
    },

    onKSSJChange(date, dateString) {
      this.form.KSSJ = dateString;
    },

    onJSSJChange(date, dateString) {
      this.form.JSSJ = dateString;
    },

    //取消
    onCancel() {
      this.$refs.ruleForm.resetFields();
      this.form.KSSJ = "";
      this.form.JSSJ = "";
      // history.go(-1);
      this.$router.go(-1);
    },

    //提交
    onSubmit() {
      let me = this;
      console.log(this.form);
      this.$refs.ruleForm.validate((valid) => {
        if (valid) {
          if (this.form.KSSJ > this.form.JSSJ) {
            me.error("开始时间不能大于结束时间");
            return;
          }

          //编辑
          if (!this.datedisabled) {  //修改时，开始时间能够编译
            if (
              dayjs(this.form.KSSJ).format("YYYY-MM-DD") < dayjs().format("YYYY-MM-DD")
            ) {
              me.error("开始时间不能小于当前时间");
              return;
            }
          }


          if (!this.datedisabledJS) {  //修改时，结束时间能够编译
            if (
              dayjs(this.form.JSSJ).format("YYYY-MM-DD") < dayjs().format("YYYY-MM-DD")
            ) {
              me.error("结束时间不能小于当前时间");
              return;
            }
          }



          // me.HJJC_STATION_OUTAGE_ADD();
          if (this.fileList && this.fileList.length > 0) {
            me.HJJC_STATION_OUTAGE_ADD();
          } else {
            me.error("请上传一条附件");
          }
        }
      });
    },

    //修改
    onModify() {
       //状态 1 编辑    2 添加
      if (this.status == 1) {
        this.status = 2;
        if (dayjs(this.form.KSSJ).format("YYYY-MM-DD") >= dayjs().format("YYYY-MM-DD")) {
          this.datedisabled = false;
        }
        if (dayjs(this.form.JSSJ).format("YYYY-MM-DD") >= dayjs().format("YYYY-MM-DD")) {
          this.datedisabledJS = false;
        }
      } 
    },

    //删除
    onDelete() {
      let me = this;
      const h = this.$createElement;
      this.$confirm({
        title: "提示",
        centered: true,
        content: h("div", {}, [h("p", "您确定要删除这条信息么？")]),
        onOk() {
          me.HJJC_STATION_OUTAGE_DELETE();
        },
        onCancel() {
          console.log("取消");
        },
      });
    },

    HJJC_STATION_OUTAGE_DELETE() {
      let me = this;
      let TYID = this.$route.params.TYID;
      http({
        url: "/data/q30/HJJC_STATION_OUTAGE_DELETE.aspx",
        data: {
          CKM: this.$store.state.loggedIn.CKM,
          TYID: TYID,
        },
        success(obj) {
          console.log(obj);
          me.success("删除成功!");
          history.go(-1);
        },
        failure(obj) {},
        me,
      });
    },

    //调用提交接口
    HJJC_STATION_OUTAGE_ADD() {
      let me = this;
      let files_name = [];
      let files_adrs = [];
      for (var i = 0; i < me.fileList.length; i++) {
        files_name.push(me.fileList[i].name);
        files_adrs.push(me.fileList[i].url);
      }
      // console.log(files_name);
      // console.log(files_adrs);
      let TYID = this.$route.params.TYID || "";

      var param = {
        CKM: me.$store.state.loggedIn.CKM,
        station_bh: me.form.station_bh,
        KSSJ: me.form.KSSJ + ":00",
        JSSJ: me.form.JSSJ + ":00",
        TYYY: me.form.TYYY,
        files_name: files_name.join("|||"),
        files_adrs: files_adrs.join("|||"),
        TYID: TYID,
      };
      console.log(param);
      http({
        url: "/data/q30/HJJC_STATION_OUTAGE_ADD_V2.aspx",
        data: param,
        success(obj) {
          history.go(-1);
          me.success("提交成功");
        },
        failure(obj) {},
        me,
      });
    },

    beforeUpload(file) {
      // 禁用原来上传,改用自定义上传,[ 必须返回false,才能在handleChange使用自己的请求方式]
      return false;
    },

    //上传文件
    handleFileChange(info) {
      var me = this;
      console.log(info);
      // console.log(info.file);
      // console.log(info.file.status);
      if (info.file.status == "removed") {
        //删除
        me.fileList = info.fileList;
      } else {
        this.popContainer = true;

        if (info.file.status === "uploading") {
          this.loading = true;
          return;
        }
        if (info.file.status === "done") {
          // Get this url from response in real world.
          getBase64(info.file.originFileObj, (imageUrl) => {
            this.loading = false;
          });
        }

        let formData = new FormData();
        // 上传的文件
        formData.append("file", info.file);
        axios
          .post("/data/hb/UploadFiles.aspx", formData, {
            "Content-Type": "multipart/form-data",
          })
          .then((res) => {
            me.popContainer = false;

            console.log(res);
            if (res.data.result == 0) {
              let Data = res.data.Data;
              if (Data && Data.length > 0) {
                if (Data[0].result == 0) {
                  me.fileList.push({
                    uid: info.file.uid,
                    name: info.file.name,
                    status: "done",
                    url: me.getFile(Data[0].FJID, Data[0].FJLX),
                  });
                } else {
                  // me.$message.error(Data[0].errMsg, 6);
                  me.error(Data[0].errMsg);
                }
              }
            } else {
              // me.$message.error("上传失败", 6);
              me.error("上传失败");
            }
          })
          .catch((err) => {
            me.popContainer = false;
          });
      }
    },

    /**
     * 获取文件的url地址链接。
     * @param {any} guid
     * @param {any} FJLX
     */
    getFile(guid, FJLX) {
      var url = "";
      var webUrl = webUrl || "http://ftp.allspark.top";

      if (guid) {
        var guid1 = guid.substring(0, 2);
        var guid2 = guid.substring(2, 4);
        var guid3 = guid.substring(4, 6);
        var guid4 = guid.substring(6, 8);
        var str =
          "/" +
          guid1 +
          "/" +
          guid2 +
          "/" +
          guid3 +
          "/" +
          guid4 +
          "/" +
          guid +
          "." +
          FJLX +
          "";
        url = webUrl + str + "";
      }
      return url;
    },

    error(message) {
      this.$error({
        title: "提示",
        centered: true,
        content: message,
      });
    },

    success(message) {
      this.$success({
        title: "提示",
        centered: true,
        content: message,
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.add-base {
  display: flex;
  flex-direction: column;
  height: 100%;
  background-color: #ffffff;
  padding-top: 20px;

  .popContainer {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.6);
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .header {
    flex: 1;
  }

  .base-body {
    &::v-deep .ant-form {
      margin: 0 auto;
      width: 800px;
    }
  }

  .footer {
    flex: 1;
  }
}
</style>
